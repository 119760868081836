import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";

const Cookies = () => (
  <Layout>
    <Seo title="Nosotros" />
    <Header />
    <main className="cookies">
      <h1>Política de Cookies</h1>
      <h2>Información esencial sobre Cookies</h2>
      <p>
        El presente sitio&nbsp;
        <a href="http://www.cenfarte.com">http://www.cenfarte.com</a> es
        titularidad de <strong>CENFARTE. S.A.</strong> Esta web utiliza cookies
        propias y de terceros para mejorar la experiencia de usuario y ofrecer
        contenidos adaptados a sus intereses, mediante la personalización de
        espacios publicitarios. Con el registro en la web y/o la mera navegación
        (con el navegador habilitado para aceptar cookies), el usuario acepta la
        instalación de cookies, salvo que se oponga a ello, tal y como se
        informa en nuestra Política de Cookies.
      </p>
      <h2>Política de Cookies</h2>
      <p>
        Nuestra Política de Cookies está sujeta a actualizaciones periódicas. Su
        propósito es ayudarle a comprender el uso que hacemos de las cookies, la
        finalidad de las cookies utilizadas, así como las opciones que tiene el
        usuario para gestionarlas.
      </p>
      <p>
        Los usuarios pueden acceder a esta información en todo momento a través
        del link habilitado en la web. Asimismo podrán modificar sus
        preferencias sobre la aceptación de cookies a través de las opciones de
        su navegador.
      </p>
      <h2>¿Qué es una Cookie?</h2>
      <p>
        Una “Cookie” es un pequeño archivo de texto que un sitio web almacena en
        el navegador del usuario. Las cookies facilitan el uso y la navegación
        por una página web y son esenciales para el funcionamiento de internet,
        aportando innumerables ventajas en la prestación de servicios
        interactivos.
      </p>
      <p>
        Las cookies se utilizan, por ejemplo, para gestionar la sesión del
        usuario (reduciendo el número de veces que tiene que incluir su
        contraseña), mejorar los servicios ofrecidos, o para adecuar los
        contenidos de una página web a sus preferencias.
      </p>
      <p>
        Las cookies pueden ser de “sesión”, por lo que se borrarán una vez el
        usuario abandone la página web que las generó o “persistentes”, que
        permanecen en su ordenador hasta una fecha determinada.
      </p>
      <p>
        Asimismo, las cookies pueden ser “propias”, gestionadas por el dominio
        al que el usuario accede y del que solicita un determinado servicio (en
        este caso los domicilio titularidad de CENFARTE) o “cookies de
        terceros”, enviadas al equipo de usuario desde un dominio diferente al
        que se accede.
      </p>
      <h2>Cookies utilizadas</h2>
      <p>
        En los siguientes puntos recogen las cookies utilizadas por todos los
        sitios que abarca el estudio, tanto las de la propia aplicación como las
        de terceros.
      </p>
      <ul className="tipo2">
        <li>
          <strong>Cookie de sesión (JSESSIONID)</strong>: Sirve para identificar
          inequívocamente a cada usuario que accede a la aplicación Esta cookie
          es necesaria para usar estos sitios web, por lo que si el usuario
          decide eliminar esta cookie de su dispositivo, el sitio web no
          funcionará correctamente.
        </li>
        <li>
          <strong>
            Cookies de terceros o Cookies Analíticas (Google Analytics)
          </strong>
          : estas cookies recaban datos estadísticos sobre el uso y navegación
          del usuario por el Sitio Web. EDP no recibe en ningún caso información
          personal. En el caso de los subdominios estas cookies pueden estar
          duplicadas.
          <ul>
            <li>
              <strong>_utma</strong>: Esta cookie genera un ID de usuario único,
              que es el que se utiliza para hacer recuento de cuantas veces
              visita el sitio un usuario. También registra cuando fue la primera
              y la última vez que vistió la web. Esta cookies es de tipo
              persistente.
            </li>
            <li>
              <strong>_utmb, _utmc</strong>: Estas cookies se usan para calcular
              cuando se ha terminado una sesión.
            </li>
            <li>
              <strong>_utmz</strong>: Esta cookie es la que se encarga de
              registrar el origen del usuario, así como las keywords.
            </li>
          </ul>{" "}
        </li>
        <li>
          <strong>Cookies de rendimiento</strong>: necesarias para el
          mantenimiento de contenidos y navegación, de las que sólo es necesario
          informar sobre su existencia.
          <ul>
            <li>
              <strong>cookiesDirective</strong>: Identificar si el usuario no
              desea que se le vuela a mostrar el mensaje de uso de cookies.
            </li>
            <li>
              <strong>cookiesDisclosureCount</strong>: Identificar el número de
              veces que se ha accedido a la web para no mostrar el mensaje.
            </li>
          </ul>{" "}
        </li>
      </ul>
      <p>
        <strong>Más información</strong>:{" "}
        <a href="http://www.google.com/intl/es/analytics/privacyoverview.html">
          http://www.google.com/intl/es/analytics/privacyoverview.html
        </a>
      </p>
      <h2>Desahibilitar el uso de Cookies</h2>
      <p>
        Si el usuario así lo desea, es posible dejar de aceptar las Cookies del
        navegador, o dejar de aceptar las Cookies de un servicio en particular.
      </p>
      <p>
        Todos los navegadores modernos permiten cambiar la configuración de
        Cookies. Estos ajustes normalmente se encuentran en las "Opciones" o
        "Preferencias" del menú del navegador.
      </p>
      <p>
        El usuario podrá, en cualquier momento, deshabilitar el uso de Cookies
        en este sitio web mendiante:
      </p>
      <ul className="tipo2">
        <li>
          <strong>La configuración del navegador</strong>, por ejemplo:
          <ul>
            <li>
              <strong>Google Chrome</strong>:{" "}
              <a
                href="http://support.google.com/chrome/answer/95647"
                target="_blank"
                rel="noopener noreferrer"
                title="Se abre en ventana nueva"
              >
                http://support.google.com/chrome/answer/95647
              </a>
            </li>
            <li>
              <strong>Internet Explorer</strong>:{" "}
              <a
                href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
                target="_blank"
                rel="noopener noreferrer"
                title="Se abre en ventana nueva"
              >
                http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
              </a>
            </li>
            <li>
              <strong>Mozilla Firefox</strong>:{" "}
              <a
                href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                target="_blank"
                rel="noopener noreferrer"
                title="Se abre en ventana nueva"
              >
                http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
              </a>
            </li>
            <li>
              <strong>Apple Safari</strong>:{" "}
              <a
                href="http://support.apple.com/kb/ph5042"
                target="_blank"
                rel="noopener noreferrer"
                title="Se abre en ventana nueva"
              >
                http://support.apple.com/kb/ph5042
              </a>
            </li>
          </ul>{" "}
        </li>
        <li>
          <strong>Otras herramientas de terceros</strong>, disponibles on-line,
          que permiten a los usuarios detectar las Cookies en cada sitio web que
          visita y gestionar su desactivación (por ejemplo, Ghostery:{" "}
          <a
            href="http://www.ghostery.com/privacy-statement"
            target="_blank"
            rel="noopener noreferrer"
            title="Se abre en ventana nueva"
          >
            http://www.ghostery.com/privacy-statement
          </a>
          )
        </li>
      </ul>
    </main>
  </Layout>
);

export default Cookies;
